<template>
  <div class="movies">
    <menuTemplate :screens="screens" :menu="menu"></menuTemplate>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'movies',
    props: {
        menuDetail: {
            type: Object
        }
    },
    data() {
        return {
            screens: null,
            menu: null
        }
    },
    computed: {
        ...mapGetters(["appConfig"])
    },
    created() {
        //load category if prop is undefined.
        if (this.menuDetail === undefined) {
            let index = this.appConfig.menu.findIndex((element) => {
                return element.id === "MOVIES"
            })
            this.loadTemplate(this.appConfig.menu[index]);
        } else {
            this.loadTemplate(this.menuDetail);
        }
    },
    methods: {
        //function to load the home template screen with provided menu screens.
        loadTemplate(menu) {
            if (menu) {
                this.menu = menu;

                let index = this.appConfig.screens.findIndex(element => {
                return menu.id === element.id;
                });
                
                if (index > -1) {
                this.screens = this.appConfig.screens[index];
                }
            }
        },
    },
    components: {
        menuTemplate: () => 
            import(/* webpackChunkName: "menuTemplate" */ "@/components/Templates/menuTemplate.vue"),
    }
}
</script>

<style lang='scss' scoped>
</style>